<template>
  <img id="logo" style="margin-top: 30px" src="~@/assets/logo.png" height="55" alt="logo" /><br />
  <div style="size: 9pt">{{ time }}</div>
  <h1 style="color: #000000; margin-top: 0"><i class="el-icon-info" /> 헬륨펌프 상태 정보</h1>
  <el-space wrap style="margin: 0">
    <el-card class="box-card" style="width: 360px">
      <template #header>
        <div class="card-header">
          <span style="color: #409eff; font-size: 24px"
            ><strong><i class="el-icon-s-platform" /> 현재 값</strong></span
          >
        </div>
      </template>
      <div class="text item">
        <ul style="list-style-type: none; line-height: 50px; padding: 0">
          <li v-for="(value, index) in valueTitle" v-bind:key="index">
            <el-input class="valueView" style="width: 320px" v-model="valueView[index]">
              <template #prepend>{{ value }}</template>
            </el-input>
          </li>
        </ul>
      </div>
    </el-card>
  </el-space>

  <el-space wrap style="margin: 0">
    <el-card class="box-card" style="width: 360px">
      <template #header>
        <div class="card-header">
          <span style="color: #67c23a; font-size: 24px"
            ><strong><i class="el-icon-s-order" /> 작동 상태</strong></span
          >
        </div>
      </template>
      <div class="text item">
        <ul style="list-style-type: none; padding: 0">
          <li v-for="(value, index) in valueMessage[0]" v-bind:key="index" class="alert">
            <el-switch
              :active-text="value"
              v-model="value0[index]"
              active-value="1"
              inactive-value="0"
              active-color="#67C23A"
              disabled
            />
          </li>
        </ul>
      </div>
    </el-card>
  </el-space>

  <el-space wrap style="margin: 0">
    <el-card class="box-card" style="width: 360px">
      <template #header>
        <div class="card-header">
          <span style="color: #e6a23c; font-size: 24px"
            ><strong><i class="el-icon-warning" /> 경고 발생</strong></span
          >
        </div>
      </template>
      <ul style="list-style-type: none; padding: 0">
        <li v-for="(value, index) in valueMessage[1]" v-bind:key="index" class="alert">
          <el-switch
            :active-text="value"
            v-model="value1[index]"
            active-value="1"
            inactive-value="0"
            active-color="#e6a23c"
            disabled
          />
        </li>
      </ul>
    </el-card>
  </el-space>

  <el-space wrap style="margin: 0">
    <el-card class="box-card" style="width: 360px">
      <template #header>
        <div class="card-header">
          <span style="color: #f56c6c; font-size: 24px"
            ><strong><i class="el-icon-message-solid" /> 긴급 작동 중지</strong></span
          >
        </div>
      </template>
      <div class="text item">
        <ul style="list-style-type: none; padding: 0">
          <li v-for="(value, index) in valueMessage[2]" v-bind:key="index" class="alert">
            <el-switch
              :active-text="value"
              v-model="value2[index]"
              active-value="1"
              inactive-value="0"
              active-color="#F56C6C"
              disabled
            />
          </li>
        </ul>
        <ul style="list-style-type: none; padding: 0">
          <li v-for="(value, index) in valueMessage[3]" v-bind:key="index" class="alert">
            <el-switch
              :active-text="value"
              v-model="value3[index]"
              active-value="1"
              inactive-value="0"
              active-color="#F56C6C"
              disabled
            />
          </li>
        </ul>
      </div>
    </el-card>
  </el-space>
  <p></p>
  <a :href="pmmUrl" target="_blank" style="text-decoration: none">
    <el-button
      type="warning"
      style="width: 100%; height: 50px; font-size: 20px; font-weight: bolder"
      >그래프 보기</el-button
    >
  </a>
  <p></p>
  <el-button type="text" @click="phoneDialog = true">[알림 전화번호 변경]</el-button>
  <el-dialog title="알림 전화번호 변경" v-model="phoneDialog" width="75%">
    <el-input
      v-for="(value, index) in 6"
      v-bind:value="value"
      v-bind:key="index"
      v-model="valuePhones[index]"
      style="padding-bottom: 5px"
      type="number"
      inputmode="numeric"
    >
    </el-input>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="phoneDialog = false">취소</el-button>
        <el-button type="primary" @click="changePhones">변경하기</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import * as messagesApi from '@/api/messages'
import * as phonesApi from '@/api/phones'

export default {
  name: 'Modbus',
  components: {},
  data() {
    return {
      time: 'Status',
      beolgyo1: '',
      beolgyo2: '',
      originalText: '',
      isAdmin: false,
      pmmUrl: process.env.VUE_APP_PMM_URL,
      password: '',
      newPassword: '',
      rePassword: '',
      passwordDialog: false,
      phoneDialog: false,
      log: [],
      logString: '',
      valueView: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
      oldValueFirst: 0,
      oldValueSecond: 0,
      num: 0,
      value0: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      value1: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      value2: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      value3: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      valueTitle: [],
      valueMessage: [],
      valuePhones: ['', '', '', '', '', '', '', ''],
      phoneView: [],
      selected: 'chart',
      currentView: 'chart'
    }
  },
  methods: {
    getMessages(address) {
      const tempTitle = []
      return new Promise((resolve) => {
        setTimeout(() => {
          messagesApi
            .messages(address)
            .then((response) => {
              response.data.result.forEach((element, index) => {
                tempTitle[index] = element.content
              })
              this.valueMessage[address] = tempTitle
            })
            .catch((error) => {
              console.log(error)
            })
          return resolve(true)
        }, 0)
      })
    },
    getTitles() {
      const tempTitle = []
      return new Promise((resolve) => {
        setTimeout(() => {
          messagesApi
            .titles()
            .then((response) => {
              response.data.result.forEach((element, index) => {
                tempTitle[index] = element.content
              })
              this.valueTitle = tempTitle
            })
            .catch((error) => {
              console.log(error)
            })
          return resolve(true)
        }, 0)
      })
    },
    getDateTime() {
      const date = new Date()
      let hour = date.getHours()
      hour = (hour < 10 ? '0' : '') + hour
      let min = date.getMinutes()
      min = (min < 10 ? '0' : '') + min
      let sec = date.getSeconds()
      sec = (sec < 10 ? '0' : '') + sec
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      month = (month < 10 ? '0' : '') + month
      let day = date.getDate()
      day = (day < 10 ? '0' : '') + day
      return `${year}-${month}-${day} ${hour}:${min}:${sec}`
    },
    getPhones() {
      const tempPhones = []
      return new Promise((resolve) => {
        setTimeout(() => {
          phonesApi
            .phones()
            .then((response) => {
              response.data.result.forEach((element, index) => {
                tempPhones[index] = element.number
              })
              this.valuePhones = tempPhones
              console.log(this.valuePhones)
            })
            .catch((error) => {
              console.log(error)
            })
          return resolve(true)
        }, 0)
      })
    },
    changePhones() {
      return new Promise((resolve) => {
        setTimeout(() => {
          phonesApi
            .changePhones(this.valuePhones)
            .then((response) => {
              if (response.data.result !== 'fail') {
                console.log('알림 전화번호 변경에 성공 하였습니다.')
                console.log(`RESULT: ${response.data.result}`)
                this.getPhones()
                this.phoneDialog = false
              } else {
                ElMessage.error('Oops, this is a error message.')
              }
            })
            .catch((error) => {
              console.log(error)
            })
          return resolve(true)
        }, 0)
      })
    }
  },
  created() {
    this.getMessages(0)
    this.getMessages(1)
    this.getMessages(2)
    this.getMessages(3)
    this.getTitles()
    this.getPhones()

    this.$socket.on('value', (data) => {
      const temp = data.message.replace('value_[', '').replace(']', '')
      this.valueView = temp.split(', ')
      this.valueView[0] += ' kWh'
      this.valueView[1] += ' Bar'
      this.valueView[2] += ' Bar'
      this.valueView[3] += ' Bar'
      this.valueView[4] += ' Bar'
      this.valueView[5] += ' Bar'
      this.valueView[6] += ' Bar'
      this.valueView[7] += ' Bar'
      this.valueView[8] += ' Bar'
      this.valueView[9] += ' Bar'
      this.valueView[10] += ' ℃'
      this.valueView[11] += ' ℃'
      this.valueView[12] += ' ℃'
    })

    this.$socket.on('input0', (data) => {
      this.value0 = data.message.replace('input0_', '').split('')
    })

    this.$socket.on('input1', (data) => {
      this.value1 = data.message.replace('input1_', '').split('')
    })

    this.$socket.on('input2', (data) => {
      this.value2 = data.message.replace('input2_', '').split('')
    })

    this.$socket.on('input3', (data) => {
      this.value3 = data.message.replace('input3_', '').split('')
    })

    this.$socket.on('log', (data) => {
      this.log.push(`[${this.getDateTime()}] ${data.message.replace('log_', '')}\r\n`)
      if (this.log.length > 1000) {
        this.log.shift()
      }
      this.logString = this.log.reverse().toString().replaceAll(',', '').slice(0, -2)
    })

    this.$socket.on('beolgyo1', (data) => {
      this.beolgyo1 = data.message
      this.beolgyo1 += ' mmH2O'
    })

    this.$socket.on('beolgyo2', (data) => {
      this.beolgyo2 = data.message
      this.beolgyo2 += ' Bar'
    })
    this.$socket.on('time', (data) => {
      this.time = data.message
    })
  }
}
</script>

<style>
.valueView input {
  text-align: right;
  font-size: 26px;
  font-weight: bolder;
  height: 50px;
}
.valueView2 input {
  text-align: right;
  font-size: 26px;
  font-weight: bolder;
  height: 50px;
}
ul {
  text-align: left;
}
.alert {
  height: 30px;
}
.valueView .el-input-group__prepend {
  width: 120px;
  font-size: 18px;
  font-weight: bold;
  color: #409eff;
}
.valueView2 .el-input-group__prepend {
  width: 60px;
  font-size: 18px;
  font-weight: bold;
  color: #409eff;
}
.el-switch__label * {
  font-size: 16px;
}
</style>
